<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";


export default {
  page: {
    title: "Modifier un type de garantie",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Modifier un type de garantie",
      suppliers: [],
      users: [],
      directions: [],
      divisions: [],
      departments: [],
      services: [],
      types: [],
      typeForm: {
        uuid: "",
        name: "",
        description: "",
        type: "",
      },
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Paramètres",
          href: "/",
        },
        {
          text: "Contrats",
          href: "/",
        },
        {
          text: "Types de garanties",
          href: "/",
        },
        {
          text: "Modification",
          active: true,
        },
      ],
    };
  },

  validations: {
    typeForm: {
      name: { required, maxLength: maxLength(64) },
      type: { required  },
    },
  },

  mounted() {
      this.fetchTypes();
      this.fetchTypeData();
  },

  methods: {
    fetchTypes(){
      this.$http
        .post("/contracts/warranty_types/getTypes")

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.types = res.data.original.types;
              break;

            case 500:
                this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
            this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    fetchTypeData() {
      this.$http
        .post("/contracts/warranty_types/getSinglePenaltyTypeData", {
          uuid: this.$route.params.uid,
        })
        .then((res) => {
          var status = res.data.original.status;
          var type = res.data.original.data;
          switch (status) {
            case 200:
                this.typeForm.uuid = type.uuid;
                this.typeForm.name = type.name;
                this.typeForm.description = type.description;
                this.typeForm.type = type.typeObj;
              break;

            case 404:
                this.$toast.warning(res.data.original.msg);
              break;

            case 500:
                this.$toast.error(res.data.original.msg);
              break;
          }
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http
        .post("/contracts/warranty_types/update", this.typeForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "settings.warranty_types.index" });
              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              break;

              case 404:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label>
                    <input
                      id="projectname"
                      v-model="typeForm.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.typeForm.name.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.typeForm.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeForm.name.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Type</label>
                    <multiselect
                      v-model="typeForm.type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="types"
                      placeholder="Select Type"
                      :allow-empty="false"
                      :class="{
                        'is-invalid': submitted && $v.typeForm.type.$error,
                      }"
                    >
                      <template slot="singleRole" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.typeForm.type.$error" class="invalid-feedback">
                      <span v-if="!$v.typeForm.type.required">La Type est obligatoire.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Description</label>
                    <input
                      id="projectname"
                      v-model="typeForm.description"
                      type="text"
                      class="form-control"
                      
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
